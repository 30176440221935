@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/typography';

.QuestionAndAnswer {
  width: 100%;

  button h3 {
    @include typography.bpk-heading-4;

    @include breakpoints.bpk-breakpoint-mobile {
      @include typography.bpk-heading-5;
    }
  }
}
