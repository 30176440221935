@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '../../../mixins';

.FAQs {
  @include mixins.falcon-text-component-max-width;

  &__header {
    margin-bottom: tokens.bpk-spacing-md();
  }

  &__questions {
    color: tokens.$bpk-text-primary-day;
  }

  &__image {
    position: relative;
    width: 100%;
    height: tokens.$bpk-one-pixel-rem * 480;
    margin-top: tokens.bpk-spacing-xxl();
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
